import React from 'react';
import { Box, CssBaseline, Container, Typography, Button, Grid } from '@mui/material';
import sampleVideo from '../Assets/V/test.mp4'; // Import the video from assets
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

import PeopleIcon from '@mui/icons-material/People';
import FlagIcon from '@mui/icons-material/Flag';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import logo from '../Assets/water.png'
import '../Assets/style/style.css'
// React
import { delay, motion } from "framer-motion"

// React Server Components
// import * as motion from "framer-motion/client"
const Home = () => {
  const agents = [
    {
      country: "Australia",
      phone: "+61 7 7010 1111",
      flagUrl: "https://upload.wikimedia.org/wikipedia/en/b/b9/Flag_of_Australia.svg", // Example URL for flag
    },
    {
      country: "Turkey",
      phone: "+90 242 461 5109",
      flagUrl: "https://upload.wikimedia.org/wikipedia/commons/b/b4/Flag_of_Turkey.svg", // Example URL for flag
    },
    {
      country: "Iraq",
      phone: "+964 0750 487 5067",
      flagUrl: "https://upload.wikimedia.org/wikipedia/commons/f/f6/Flag_of_Iraq.svg", // Example URL for flag
    },
    {
      country: "Belgium",
      phone: "+32 456 371 9388",
      flagUrl: "https://upload.wikimedia.org/wikipedia/commons/6/65/Flag_of_Belgium.svg",
    },
    {
      country: "Canada",
      phone: "+1-613-555-0179",
      flagUrl: "https://upload.wikimedia.org/wikipedia/commons/d/d9/Flag_of_Canada_%28Pantone%29.svg",
    },
    {
      country: "Ukraine",
      phone: "+380 44 197 2117",
      flagUrl: "https://upload.wikimedia.org/wikipedia/commons/4/49/Flag_of_Ukraine.svg",
    },
  ];
  const statistics = [
    {
      icon: <PeopleIcon sx={{ fontSize: 50, color: '#f57c00' }} />,
      value: '100,000 +',
      label: 'Customers',
    },
    {
      icon: <FlagIcon sx={{ fontSize: 50, color: '#f57c00' }} />,
      value: '6 +',
      label: 'Agent',
    },
    {
      icon: <AttachMoneyIcon sx={{ fontSize: 50, color: '#f57c00' }} />,
      value: '14 M+',
      label: 'Support Given',
    },
    {
      icon: <EmojiEventsIcon sx={{ fontSize: 50, color: '#f57c00' }} />,
      value: '26',
      label: 'Certificate',
    },
  ];
  const Models = [
    {
      country: "GMC-150L",
      phone: "Model : GMC-150L , VOLTAGE : 220 V~/50Hz , POWER : 1500WATTS , WEIGHT : 29.0kg GROSS/31.0kg NET , PACKING DIMENSION : 460x460x1085mm , CAPACITY : 150L",
      flagUrl: logo, // Example URL for flag
    },
    {
      country: "GMC-100L",
      phone: "Model : GMC-100L VOLTAGE : 220 V~/50Hz POWER : 1500WATTS WEIGHT : 28.0kg GROSS/30.0kg NET PACKING DIMENSION : 460x460x985mm CAPACITY : 100L",
      flagUrl: logo, // Example URL for flag
    },
    {
      country: "GMC-80L",
      phone: "Model : GMC-80L VOLTAGE : 220 V~/50Hz POWER : 1500WATTS WEIGHT : 21.1 kg GROSS/18.6kg NET PACKING DIMENSION : 460x460x820mm CAPACITY : 80L",
      flagUrl: logo, // Example URL for flag
    },
    {
      country: "GMC-60L",
      phone: "Model : GMC-60L VOLTAGE : 220 V~/50Hz POWER : 1500WATTS WEIGHT : 18.2kg GROSS/15.6kg NET PACKING DIMENSION : 460x460x645mm CAPACITY : 60L",
      flagUrl: logo, // Example URL for flag
    },


  ];

  return (
    <>
      <Container>
        <Box sx={{ marginTop: 20, marginBottom: 20, backgroundColor: "white" }}>

          <Grid container spacing={4} alignItems="center">
            {/* Text Section */}
            {/* <Grid  dir="rtl" item xs={12} md={6}> */}

            <Grid item xs={12} md={6}>
              <Box display="flex" justifyContent="center">
                <img src={logo} alt="Office Illustration" style={{ maxWidth: '35%', borderRadius: '16px' }} />
              </Box>
            </Grid>



            <Grid item xs={12} md={6}>
              {/* <motion.div
                initial={{ x: "100%" }}
                animate={{ x: "0%" }}
                transition={{ duration: 0.6 }}
              > */}
              <Typography variant="h4" sx={{ color: "#EB6623", fontWeight: 'bold', mb: 2 }}>
                PRODUCT FROM GMC USA
              </Typography>
              {/* </motion.div> */}

              <Typography variant="body1" sx={{ color: '#6c757d', mb: 2 }}>
                {/* <motion.div
                  //transformTemplate={template}
                  animate={{ rotate: 360 }}
                  style={{ rotate: 0, x: "calc(50vh - 100px)" }}
                  transition={{ duration: 2 }}

                > */}
                <Typography variant="h5" sx={{ color: "#6c757d", fontWeight: 'bold', mb: 2 }}>
                  Real Feature True Benefits
                </Typography>
                {/* </motion.div> */}
                <ul>
                  <li>Enamel coated steel tank</li>
                  <li> High precision thermostat control</li>
                  <li> Preset thermal cut-out for double care</li>
                  <li>  Multi-functional safety valve</li>
                  <li>  High density CFC free PUF insulation</li>
                  <li>  Withstand 22 bar water pressure</li>

                </ul>
              </Typography>
            </Grid>
          </Grid>
        </Box>




        <Box sx={{ marginTop: 20, marginBottom: 20, backgroundColor: "white" }}>

          <Grid container spacing={4} alignItems="center">
            {/* Text Section */}
            {/* <Grid  dir="rtl" item xs={12} md={6}> */}
            <Grid item xs={12} md={6}>
              <Typography variant="h4" sx={{ textAlign: 'center', color: "#EB6623", fontWeight: 'bold', mb: 2 }}>
                PRODUCT FROM GMC USA
              </Typography>

              <Typography variant="body1" sx={{ textAlign: 'center', color: '#6c757d', mb: 2 }}>

                GMC Water Heaters is a leading manufacturer of high-quality water heater products with manufacturing

              </Typography>
            </Grid>


            <Grid item xs={12} md={4}>
              <Box display="flex" justifyContent="center">
                <video height="400" width="600" controls>
                  <source src={sampleVideo} type="video/mp4" />
                </video>
              </Box>
            </Grid>

          </Grid>
        </Box>






        <Box sx={{ marginTop: 30, marginBottom: 30 }}>



          <Grid sx={{ paddingBottom: 10 }} >
            <Typography variant="h4" sx={{ textAlign: 'center', color: "#EB6623", fontWeight: 'bold', mb: 2 }}>
              GMC - Models
            </Typography>

            <Typography variant="body1" sx={{ textAlign: 'center', color: '#6c757d', mb: 2 }}>

              the american water heater GMC are available with different models and capacity, below are all models and details


            </Typography>
          </Grid>


          <Grid container spacing={8} justifyContent="center">
            {Models.map((agent, index) => (
              <Grid item xs={12} sm={2} md={3} key={index}>
                <Card sx={{  maxWidth: 345, textAlign: 'center', boxShadow: 3 }}>
                  <Box
                    component="img"
                    src={agent.flagUrl}
                    alt={`${agent.country} Flag`}
                    sx={{
                      
                      width: '60%',
                      height: 200,
                      objectFit: 'contain',
                    }}
                  />
                  <CardContent>
                    <Typography variant="h6" component="div" sx={{ color:'#f57c00', fontWeight: 'bold' }}>
                      {agent.country}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {agent.phone}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>






      



        </Box>

        <Box sx={{ marginBottom: 40, marginTop: 20, textAlign: 'center' }}>
          {/* Title */}
          <Typography variant="h4" component="h2" sx={{ fontWeight: 'bold' }}>
            Our <span style={{ color: '#EB6623' }}>Agent</span>
          </Typography>
          <Typography variant="subtitle1" sx={{ color: 'gray', mb: 4 }}>
            some of our agent in different country
          </Typography>

          {/* Agents Grid */}
          <Grid container spacing={8} justifyContent="center">
            {agents.map((agent, index) => (
              <Grid item xs={12} sm={2} md={3} key={index}>
                <Card sx={{ maxWidth: 345, textAlign: 'center', boxShadow: 3 }}>
                  <Box
                    component="img"
                    src={agent.flagUrl}
                    alt={`${agent.country} Flag`}
                    sx={{
                      width: '100%',
                      height: 150,
                      objectFit: 'cover',
                    }}
                  />
                  <CardContent>
                    <Typography variant="h6" component="div" sx={{ fontWeight: 'bold' }}>
                      {agent.country}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      Phone: {agent.phone}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>



        <Box sx={{ marginBottom: 30, marginTop: 20, textAlign: 'center', mt: 4 }}>
          {/* Title */}
          <Typography variant="h4" sx={{ color: '#f57c00', fontWeight: 'bold', mb: 4 }}>
            GMC Certification
          </Typography>

          {/* Stats Grid */}
          <Grid container spacing={4} justifyContent="center">
            {statistics.map((stat, index) => (
              <Grid item xs={12} sm={6} md={3} key={index}>
                <Box sx={{ textAlign: 'center', p: 3, boxShadow: 3, borderRadius: '50%', width: 150, height: 150, mx: 'auto', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  {stat.icon}
                </Box>
                <Typography variant="h6" sx={{ fontWeight: 'bold', mt: 2 }}>
                  {stat.value}
                </Typography>
                <Typography variant="subtitle1" color="textSecondary">
                  {stat.label}
                </Typography>
              </Grid>
            ))}
          </Grid>
        </Box>

      </Container >

      {/* Column Layout */}
      {/* <Typography variant="h6" gutterBottom style={{ marginTop: '20px' }}>
          Column Layout (Vertical)
        </Typography>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Box bgcolor="error.main" p={2} color="white">
              Row 1
            </Box>
          </Grid>
          <Grid item>
            <Box bgcolor="warning.main" p={2} color="white">
              Row 2
            </Box>
          </Grid>
          <Grid item>
            <Box bgcolor="info.main" p={2} color="white">
              Row 3
            </Box>
          </Grid>
        </Grid> */}


    </>



  );
};

export default Home;

// <React.Fragment>
//   <CssBaseline />
//   <Box maxWidth="xl" sx={{ flexDirection: 'column', alignItems: 'center', bgcolor: '#cfe8fc', height: '80vh' }}>
//     <Box sx={{  bgcolor: 'red' }}  >
//       <img src={logo} alt="Office Illustration" height={150} width={135} style={{ maxWidth: '100%', borderRadius: '16px' }} />
//     </Box>
//     <Box sx={{ bgcolor: 'blue' }}  >
//       <img src={logo} alt="Office Illustration" height={150} width={135} style={{ maxWidth: '100%', borderRadius: '16px' }} />
//     </Box>
//   </Box>
// </React.Fragment>