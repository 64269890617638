// src/components/Header.js
import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, Button, IconButton, Drawer, List, ListItem, ListItemText, Box } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Link } from 'react-router-dom';
import logo from '../Assets/MG-logoy.png'

const Header = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md')); // Check if screen size is medium or smaller

  // Navigation links
  const navLinks = [
    { title: 'Home', path: '/' },
    { title: 'Catalog', path: '/Catalog' },
    { title: 'About', path: '/about' },
    { title: 'Contact', path: '/contact' }
  ];

  // Toggle drawer for mobile menu
  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  // Drawer for mobile menu
  const drawer = (
    <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
      <List>
        {navLinks.map((link) => (
          <ListItem button component={Link} to={link.path} key={link.title} onClick={toggleDrawer(false)}>
            <ListItemText primary={link.title} />
          </ListItem>
        ))}
      </List>
    </Drawer>
  );

  return (
    <AppBar position="sticky" sx={{ marginTop:2, backgroundColor:'#FABC3F', borderRadius:2}}>
      <Toolbar sx={{ justifyContent: 'space-between' }}>
        {/* Left side: Logo and Header Text */}
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <img src={logo} height={125} width={125} 
            style={{ marginRight: '10px' }}
          />
          <Typography variant="h6" component="div">
           AMERICAN WATER HEATER
          </Typography>
        </Box>

        {/* Right side: Menu items (responsive) */}
        {isMobile ? (
          <>
            <IconButton edge="end" color="inherit" aria-label="menu" onClick={toggleDrawer(true)}>
              <MenuIcon />
            </IconButton>
            {drawer}
          </>
        ) : (
          <Box>
            {navLinks.map((link) => (
              <Button color="inherit" component={Link} to={link.path} key={link.title}>
                {link.title}
              </Button>
            ))}
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Header;


















// import React from 'react';
// import { AppBar, Box, Toolbar, Typography, Button } from '@mui/material';
// import logo from '../Assets/MG-logoy.png'
// import { Link } from "react-router-dom";

// import { color, flexbox } from '@mui/system';
// const Header = () => {
//     return (
//              <AppBar style={{ backgroundColor: '#FABC3F' }}>
//                 <Toolbar>
//                     <img src={logo} height={125} width={125} />
//                     <Typography variant="h6" style={{ flexGrow: 1 }}>
//                         AMERICAN WATER HEATER
//                     </Typography>
//                     <Button color="waring"><Link style={{ color: 'white', textDecoration: 'none' }} to="/">Home</Link></Button>
//                     <Button color="waring"><Link style={{ color: 'white', textDecoration: 'none' }} to="/Catalog">Catalog</Link></Button>
//                     <Button color="waring"><Link style={{ color: 'white', textDecoration: 'none' }} to="/ContactUs">Contact Us</Link></Button>
//                     <Button color="waring"><Link style={{ color: 'white', textDecoration: 'none' }} to="/AboutUs">About Us</Link></Button>
//                 </Toolbar>
//             </AppBar >
//      );
// };

// export default Header;
