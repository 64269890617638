import React from 'react';
import { Box, Typography } from '@mui/material';

const Footer = () => {
  return (
    <Box   position="sticky"  sx={{ backgroundColor: '#FABC3F', padding: '1rem', textAlign: 'center', color: '#fff' }}>
      <Typography variant="body1">
        © {new Date().getFullYear()} GMC Water Heaters. All rights reserved.
      </Typography>
    </Box>
  );
};

export default Footer;
