// src/components/PDFIframe.js
import React from 'react';
import pdfFile from '../Assets/PDF/catalog.pdf';

const PDFIframe = () => {
  return (
    <div style={{ justifyItems:'center', width: '100%', height: '100vh' }}>
      <iframe
        src={pdfFile}
        title="PDF"
        width="100%"
        height="100%"
        frameBorder="0"
      ></iframe>
    </div>
  );
};

export default PDFIframe;
