import React from "react";
import { Grid, Typography, Box, Divider } from "@mui/material";
import logo from '../Assets/MG-logoy.png'
function ProductSection() {
  return (
    <Box sx={{ padding: '50px 20px', maxWidth: '1200px', margin: 'auto', textAlign: 'center' }}>
      <Grid container spacing={3} alignItems="center">
        {/* Image Section */}
        <Grid item xs={12} md={6}>
          <Box
            component="img"
            src={logo} // Replace with your image path
            alt="Water Heater"
            sx={{ maxWidth: '100%', height: 'auto' }}
          />
        </Grid>

        {/* Text Section */}
        <Grid item xs={12} md={6}>
          <Typography variant="h4" gutterBottom color="orange">
            GMC AMERICAN WATER HEATERS
          </Typography>
          <Divider sx={{ width: '50px', borderBottomWidth: '2px', margin: '10px auto' }} />
          <Typography variant="h6" gutterBottom>
            GMC
          </Typography>
          <Typography variant="body1" paragraph>
            GMC American Water Heaters is a leading manufacturer of high-quality water heater products with manufacturing, research and development, and corporate offices located in Johnson City, TN. American offers a comprehensive line of residential and commercial water heaters.
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

export default ProductSection;
