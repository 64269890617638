import React from "react";
import { Grid, Typography, Box, Link } from "@mui/material";
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';

const containerStyle = {
  width: '100%',
  height: '500px'
};

const center = {
  lat: 36.3131, // You can replace this with the actual latitude
  lng: -82.3535, // You can replace this with the actual longitude
};

function ContactSection() {
  const { isLoaded } = useJsApiLoader({
    id: 'api-keyy-297119',
    googleMapsApiKey: 'AIzaSyAkPfFosD3dtbB5N9Y5Dmd1xxUhMQtL_I4' // Replace with your actual API key
  });

  return (
    <Box sx={{ paddingTop: 65, padding: '25px', maxWidth: '1200px', margin: 'auto' }}>
      <Grid container spacing={3}>
        {/* Google Map */}
        <Grid item xs={12} md={6}>
          {isLoaded ? (
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={center}
              zoom={15}
            >
              <Marker position={center} />
            </GoogleMap>
          ) : (
            <Typography>Loading Map...</Typography>
          )}
        </Grid>

        {/* Contact Information */}
        <Grid item xs={12} md={6}>
          <Typography variant="h4" gutterBottom>
            Get in touch
          </Typography>

          {/* Location */}
          <Box mb={3}>
            <Typography variant="subtitle1" fontWeight="bold">
              LOCATION
            </Typography>
            <Typography variant="body1">
              1100 E Fairview Ave, Johnson City, TN 37601, United States
            </Typography>
          </Box>

          {/* Contact Us */}
          <Box mb={3}>
            <Typography variant="subtitle1" fontWeight="bold">
              CONTACT US
            </Typography>
            <Typography variant="body1">
              Fax: +1 800 755 60 20
            </Typography>
            <Typography variant="body1">
              Email: <Link href="mailto:Info@waterheatergmc.com">Info@waterheatergmc.com</Link>
            </Typography>
          </Box>

          {/* Hours */}
          <Box>
            <Typography variant="subtitle1" fontWeight="bold">
              OUR HOURS
            </Typography>
            <Typography variant="body1">
              MON-FRI 09:00 – 19:00
            </Typography>
            <Typography variant="body1">
              SAT-SUN 10:00 – 14:00
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default ContactSection;
