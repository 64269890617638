// src/App.js
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Layout from "./components/Layout";
import Header from "./components/Header";
import Contact from "./components/ContactForm";
import Home from "./components/Home";
import Catalog from "./components/Catalog";
import About from "./components/About";
// Simple page components

function App() {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/Header" element={<Header />} />
          <Route path="/Catalog" element={<Catalog />} />
          <Route path="/About" element={<About />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;





























// import React from 'react';
// import Header from './components/Header';
// import Hero from './components/Hero';
// import Services from './components/Services';
// import ContactForm from './components/ContactForm';
// import Footer from './components/Footer';
// import { Box, Container, Grid, ThemeProvider } from '@mui/system';

// function App() {
//   return (

//     <Box sx={{ flexDirection: 'row' }}>
//       <Header />
//       <Hero />
//       <Services />
//       {/* <Container maxWidth='100%' sx={{ backgroundColor: 'white' }}>
//           <Container sx={{ paddingTop: 5, backgroundColor: 'white' }} maxWidth="lg">
//             <Contracting />
//           </Container>
//         </Container> */}

//       <Container sx={{ paddingTop: 5 }} maxWidth="lg">
//         <ContactForm />
//       </Container>
//       <Footer />
//     </Box>
//   );
// }

// export default App;
