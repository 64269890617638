// src/components/Layout.js
import { Box } from "@mui/system";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Header from "./Header";
import Footer from "./Footer";
import { Typography } from "@mui/material";
// Styled components for layout
const LayoutContainer = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
`;





const Layout = ({ children }) => {
    return (
        <LayoutContainer>
            <Header />
            <Box sx={{
                display: 'flex',
                maxHeight: '100vh',
                flexDirection: 'column',
            }}>
                  
 
                <Box sx={{ }}>
                    {children}
                </Box>
                <Footer sx={{}} />
            </Box >
        </LayoutContainer>
    );
};

export default Layout;
